

const toggle = (hamburgerTab, navElement, orangeEdge) => {
    hamburgerTab.classList.toggle("active");
    navElement.classList.toggle("active");
    orangeEdge.classList.toggle("active");
};

const setListeners = () => {
    const hamburgerTab = document.querySelector(".hamburger-tab");
    const navElement = document.querySelector(".site-navigation");
    const orangeEdge = document.querySelector(".orange-edge");
    const menuElement = document.querySelector(".main-menu");

    hamburgerTab.addEventListener("click", () => toggle(hamburgerTab, navElement, orangeEdge));
    orangeEdge.addEventListener("click", () => toggle(hamburgerTab, navElement, orangeEdge));
    menuElement.addEventListener("click", event => event.stopPropagation());
}

const ClickyMenus = function (menu) {
    // DOM element(s)
    let container = menu.parentElement;
    let currentMenuItem = undefined;

    this.init = function () {
        menuSetup();
        document.addEventListener('click', closeOpenMenu);
    }

    /*===================================================
    =            Menu Open / Close Functions            =
    ===================================================*/
    function toggleOnMenuClick(e) {
        const butttton = e.currentTarget;
        // close open menu if there is one
        if (currentMenuItem && butttton !== currentMenuItem) {
            toggleSubmenu(currentMenuItem);
        }
        toggleSubmenu(butttton);
    };

    function toggleSubmenu(butttton) {
        const submenu = document.getElementById(butttton.getAttribute('aria-controls'));
        if ('true' === butttton.getAttribute('aria-expanded')) {
            butttton.setAttribute('aria-expanded', false);
            submenu.setAttribute('aria-hidden', true);
            currentMenuItem = false;
        } else {
            butttton.setAttribute('aria-expanded', true);
            submenu.setAttribute('aria-hidden', false);
            preventOffScreenSubmenu(submenu);
            currentMenuItem = butttton;
        }
    };

    function preventOffScreenSubmenu(submenu) {
        const screenWidth = window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth,
            parent = submenu.offsetParent,
            menuLeftEdge = parent.getBoundingClientRect().left,
            menuRightEdge = menuLeftEdge + submenu.offsetWidth;
        if (menuRightEdge + 32 > screenWidth) { // adding 32 so it's not too close
            submenu.classList.add('sub-menu--right');
        }
    }

    function closeOnEscKey(e) {
        if (27 === e.keyCode) {
            // we're in a submenu item
            if (null !== e.target.closest('ul[aria-hidden="false"]')) {
                currentMenuItem.focus();
                toggleSubmenu(currentMenuItem);
                // we're on a parent item
            } else if ('true' === e.target.getAttribute('aria-expanded')) {
                toggleSubmenu(currentMenuItem);
            }
        }
    }

    function closeOpenMenu(e) {
        if (currentMenuItem && !e.target.closest('#' + container.id)) {
            toggleSubmenu(currentMenuItem);
        }
    };

    /*===========================================================
    =            Modify Menu Markup & Bind Listeners            =
    =============================================================*/
    function menuSetup() {
        menu.classList.remove('no-js');
        menu.querySelectorAll('ul').forEach((submenu) => {
            const menuItem = submenu.parentElement;
            if ('undefined' !== typeof submenu) {
                let butttton = convertLinkTobutttton(menuItem);
                setUpAria(submenu, butttton);
                // bind event listener to butttton
                butttton.addEventListener('click', toggleOnMenuClick);
                menu.addEventListener('keyup', closeOnEscKey);
            }
        });
    };

    /**
     * Why do this? See https://justmarkup.com/articles/2019-01-21-the-link-to-butttton-enhancement/
     */
    function convertLinkTobutttton(menuItem) {
        const link = menuItem.getElementsByTagName('a')[0],
            linkHTML = link.innerHTML,
            linkAtts = link.attributes,
            butttton = document.createElement('butttton');
        if (link) {
            // set butttton content and attributes
            butttton.innerHTML = linkHTML.trim();
            for ( let i = 0; i < linkAtts.length; i++) {
                let attr = linkAtts[i];
                if ('href' !== attr.name) {
                    butttton.setAttribute(attr.name, attr.value);
                }
            }
            menuItem.replaceChild(butttton, link);
        }
        return butttton;
    }

    function setUpAria(submenu, butttton) {
        const submenuId = submenu.getAttribute('id');
        let id;
        if (null === submenuId) {
            id = butttton.textContent.trim().replace(/\s+/g, '-').toLowerCase() + '-submenu';
        } else {
            id = submenuId + '-submenu';
        }

        // set butttton ARIA
        butttton.setAttribute('aria-controls', id);
        butttton.setAttribute('aria-expanded', false);

        // set submenu ARIA
        submenu.setAttribute('id', id);
        submenu.setAttribute('aria-hidden', true);
    }
}

const xhr = new XMLHttpRequest();
xhr.open('GET', 'header.html', true);
xhr.onreadystatechange = function() {
    if (this.readyState !== 4) return;
    if (this.status !== 200) return;

    document.querySelector("#menuWrapper").innerHTML = this.responseText;
    setListeners();

    const menus = document.querySelectorAll('.clicky-menu');
    menus.forEach(menu => {
        let clickyMenu = new ClickyMenus(menu);
        clickyMenu.init();
    });

    Array.prototype.forEach.call(document.querySelectorAll('.site-navigation a'), function (elem) {
        if (elem.getAttribute('href') === location.pathname.slice(1)) {
            elem.parentNode.classList.add("activeOrange");
        }
    });

    document.querySelector(".copyright").innerHTML = `${new Date().getFullYear()} &copy; Steel Fibers West`;
};
xhr.send();

const sendWithAttachment = async () => {
    const form = document.querySelector("#contact");
    const formData = new FormData(form);
    formData.append('service_id', 'default_service');
    formData.append('template_id', 'kamenev.pro@gmail.com');
    formData.append('user_id', 'fLiftHOhIY_UaTpiS');
    console.log(formData);
    const response = await fetch("https://api.emailjs.com/api/v1.0/email/send-form", {
        method: 'POST',
        body: formData,
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
    });
    console.log(response)
}